import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardPreAnestheticFormUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticForm"
        style={{ width: "100%", padding:"10px"}}>
        <div
          id="CardPreAnestheticFormUX-div-3">
          
          <div
            id="CardPreAnestheticFormUX-div-4"
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", justifyContent: "space-between", display: "flex", alingItem: "center", marginBottom: "10px"}}>
            <div
              id="CardPreAnestheticFormUX-div-5"
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Pre Anesthetic form
            </div>
            <div
              id="CardPreAnestheticFormUX-div-156"
              style={{display: "flex" }}>
              
              <div
                id="CardPreAnestheticFormUX-div-157">
                {props.buttonLog}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-158"
                style={{ display: "none" }}>
                {props.buttonPrint}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-161"
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                id="CardPreAnestheticFormUX-div-160"
                style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          id="CardPreAnestheticFormUX-div-1"
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"20px 0px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2", ...(props.isPHV && { pointerEvents: "none" })}}>
          
          <Form
            id="CardPreAnestheticFormUX-Form-164">
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-165"
              inline={true}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-166"
                inline={true}
                width={7}>
                <label
                  id="CardPreAnestheticFormUX-label-169"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Preanesthetic assessment at
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-178"
                  name="PreanestheticAssessmentAt"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_assessment_at || []}
                  search={true}
                  selection={true}
                  style={{marginRight: "0.5rem", width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || ""}>
                </Dropdown>
                <Input
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-179"
                  name="PreanestheticAssessmentAtRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-167"
                inline={true}
                width={8}>
                <label
                  id="CardPreAnestheticFormUX-label-170"
                  style={{minWidth: "37.5px", maxWidth: "37.5px"}}>
                   Body weight
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-180"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.BodyWeight || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-171"
                  style={{minWidth: "37.5px", maxWidth: "37.5px"}}>
                  kg
                </label>
                <label
                  id="CardPreAnestheticFormUX-label-174"
                  style={{minWidth: "45px", maxWidth: "45px"}}>
                  Height
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-181"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.Height || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-175"
                  style={{minWidth: "45px", maxWidth: "45px", marginRight: "-15px"}}>
                  cm
                </label>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-168"
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-172"
                  style={{minWidth: "55px", maxWidth: "55px"}}>
                  BMI
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-182"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.BMI || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-173"
                  style={{minWidth: "47.5px", maxWidth: "47.5px"}}>
                  kg/m2
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-184"
              inline={true}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-185"
                inline={true}
                width={7}>
                <label
                  id="CardPreAnestheticFormUX-label-188"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Psychological status
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-197"
                  name="PsychologicalStatus"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_psychological_status || []}
                  search={true}
                  selection={true}
                  style={{marginRight: "0.5rem", width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatus || ""}>
                </Dropdown>
                <Input
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-198"
                  name="PsychologicalStatusRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-186"
                inline={true}
                width={8}>
                <label
                  id="CardPreAnestheticFormUX-label-189"
                  style={{minWidth: "37.5px", maxWidth: "37.5px"}}>
                  Pulse
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-199"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.Pulse || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-190"
                  style={{minWidth: "37.5px", maxWidth: "37.5px"}}>
                  /min
                </label>
                <label
                  id="CardPreAnestheticFormUX-label-191"
                  style={{minWidth: "45px", maxWidth: "45px"}}>
                  RR
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-200"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.RR || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-192"
                  style={{minWidth: "45px", maxWidth: "45px", marginRight: "-15px"}}>
                  /min
                </label>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-187"
                inline={true}
                style={{padding: 0}}
                width={4}>
                <label
                  id="CardPreAnestheticFormUX-label-193"
                  style={{minWidth: "55px", maxWidth: "55px"}}>
                  Temp
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-201"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-194"
                  style={{minWidth: "47.5px", maxWidth: "47.5px"}}>
                  °C
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-203"
              inline={true}>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-206"
                inline={true}
                width={5}>
                <label
                  id="CardPreAnestheticFormUX-label-210"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Surgeon
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-214"
                  value={props.PreAnestheticSequence?.data?.Surgeon || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-207"
                inline={true}
                width={5}>
                <label
                  id="CardPreAnestheticFormUX-label-211"
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Operative Date
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-215"
                  value={props.PreAnestheticSequence?.data?.DateTime || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-242"
                inline={true}
                style={{padding:0}}
                width={8}>
                <label
                  id="CardPreAnestheticFormUX-label-243"
                  style={{minWidth: "45px", maxWidth: "45px", marginLeft: "2.5%", paddingLeft: "2.5%"}}>
                  BP
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-247"
                  style={{marginRight: "0.5rem", width: "calc(100% - -11%)", minWidth: "6.5rem"}}
                  value={props.PreAnestheticSequence?.data?.BP || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-244"
                  style={{minWidth: "45px", maxWidth: "45px"}}>
                  mmHg
                </label>
                <label
                  id="CardPreAnestheticFormUX-label-245"
                  style={{minWidth: "max-content", padding: "0 0.5rem 0 1%"}}>
                  O2 Sat RA
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-248"
                  style={{marginRight: "0.5rem"}}
                  value={props.PreAnestheticSequence?.data?.O2SatRA || ""}>
                </Input>
                <label
                  id="CardPreAnestheticFormUX-label-246"
                  style={{minWidth: "47.5px", maxWidth: "47.5px"}}>
                  %
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-235"
              inline={true}>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-236"
                inline={true}
                width={5}>
                <label
                  id="CardPreAnestheticFormUX-label-238"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  DX
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-240"
                  value={props.PreAnestheticSequence?.data?.DX || ""}>
                </Input>
              </FormField>
              <FormField
                className="--override-disabled --grey"
                id="CardPreAnestheticFormUX-FormField-237"
                inline={true}
                width={5}>
                <label
                  id="CardPreAnestheticFormUX-label-239"
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Proposed operation
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Input-241"
                  value={props.PreAnestheticSequence?.data?.ProposedOperation || ""}>
                </Input>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-249"
                width={8}>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardPreAnestheticFormUX-FormGroup-216"
              inline={true}
              style={{ alignItems: "flex-start" }}>
              <FormField
                id="CardPreAnestheticFormUX-FormField-218"
                inline={true}
                style={{ alignItems: "center" }}
                width={6}>
                <label
                  id="CardPreAnestheticFormUX-label-224"
                  style={{minWidth: "95px", maxWidth: "95px"}}>
                  Anesthesiologist
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardPreAnestheticFormUX-Dropdown-230"
                  name="Anesthesiologist"
                  onChange={props.onChangeData}
                  options={props.masterOptions.doctor || []}
                  search={true}
                  selection={true}
                  style={{width: "100%", display: "none"}}
                  value={props.PreAnestheticSequence?.data?.Anesthesiologist || ""}>
                </Dropdown>
                <div
                  id="CardPreAnestheticFormUX-div-250"
                  style={{ width: "100%" }}>
                  {props.SurgeonElement}
                </div>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-233"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="CardPreAnestheticFormUX-FormField-219"
                inline={true}
                style={{ alignItems: "flex-start" }}
                width={8}>
                <label
                  id="CardPreAnestheticFormUX-label-225"
                  style={{minWidth: "115px", maxWidth: "115px", marginTop: "7px"}}>
                  Anesthesia nurse
                </label>
                <div
                  id="CardPreAnestheticFormUX-div-234"
                  style={{ width: "100%" }}>
                  {props.AnesthetistElement}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardPreAnestheticFormUX

export const screenPropsDefault = {}

/* Date Time : Mon Feb 24 2025 17:49:00 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticForm"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"20px 0px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\", ...(props.isPHV && { pointerEvents: \"none\" })}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-3"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-4"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", justifyContent: \"space-between\", display: \"flex\", alingItem: \"center\", marginBottom: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre Anesthetic form"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-5"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-156"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-157"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-158"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-160"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-161"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Form",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Form-164"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-165"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 165,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-166"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 165,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-167"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 165,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-168"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": "Preanesthetic assessment at"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-169"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": " Body weight"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-170"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"37.5px\", maxWidth: \"37.5px\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "kg"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-171"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"37.5px\", maxWidth: \"37.5px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "BMI"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-172"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"55px\", maxWidth: \"55px\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "kg/m2"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-173"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"47.5px\", maxWidth: \"47.5px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Height"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-174"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "cm"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-175"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\", marginRight: \"-15px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Dropdown",
      "parent": 166,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-178"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_assessment_at || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAt || \"\""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Input",
      "parent": 166,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-179"
        },
        "name": {
          "type": "value",
          "value": "PreanestheticAssessmentAtRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PreanestheticAssessmentAtRemark || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Input",
      "parent": 167,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-180"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BodyWeight || \"\""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 167,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-181"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Height || \"\""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 168,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-182"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BMI || \"\""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-184"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 184,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-185"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 184,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-186"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 184,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-187"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "Psychological status"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-188"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-189"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"37.5px\", maxWidth: \"37.5px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-190"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"37.5px\", maxWidth: \"37.5px\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "RR"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-191"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "/min"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-192"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\", marginRight: \"-15px\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-193"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"55px\", maxWidth: \"55px\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "°C"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-194"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"47.5px\", maxWidth: \"47.5px\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Dropdown",
      "parent": 185,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-197"
        },
        "name": {
          "type": "value",
          "value": "PsychologicalStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_psychological_status || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatus || \"\""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Input",
      "parent": 185,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-198"
        },
        "name": {
          "type": "value",
          "value": "PsychologicalStatusRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusRemark || \"\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 186,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-199"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Pulse || \"\""
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Input",
      "parent": 186,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-200"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RR || \"\""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Input",
      "parent": 187,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-201"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PsychologicalStatusTemp || \"\""
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-203"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 203,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-206"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 203,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-207"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "label",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgeon"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-210"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Date"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-211"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Input",
      "parent": 206,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-214"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Surgeon || \"\""
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Input",
      "parent": 207,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-215"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DateTime || \"\""
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-216"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-218"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-219"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-224"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "label",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia nurse"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-225"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"115px\", maxWidth: \"115px\", marginTop: \"7px\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Dropdown",
      "parent": 218,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Dropdown-230"
        },
        "name": {
          "type": "value",
          "value": "Anesthesiologist"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Anesthesiologist || \"\""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 216,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-233"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 234,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AnesthetistElement"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-234"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormGroup-235"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 235,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-236"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 235,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-237"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "DX"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-238"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "label",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "Proposed operation"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-239"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Input",
      "parent": 236,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-240"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DX || \"\""
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Input",
      "parent": 237,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-241"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ProposedOperation || \"\""
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormField",
      "parent": 203,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-242"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-243"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\", marginLeft: \"2.5%\", paddingLeft: \"2.5%\"}"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-244"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"45px\", maxWidth: \"45px\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "O2 Sat RA"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-245"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", padding: \"0 0.5rem 0 1%\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "%"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-label-246"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"47.5px\", maxWidth: \"47.5px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "Input",
      "parent": 242,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-247"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", width: \"calc(100% - -11%)\", minWidth: \"6.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BP || \"\""
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "Input",
      "parent": 242,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-Input-248"
        },
        "readOnly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.O2SatRA || \"\""
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 235,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-FormField-249"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SurgeonElement"
        },
        "id": {
          "type": "value",
          "value": "CardPreAnestheticFormUX-div-250"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 250,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardPreAnestheticFormUX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
